import { getRandomNumber, shuffleArray } from "./utility";

export const generateCodeWordQuestion = (level) => {
  // Example words and some decoys
  const words = [
    "apple", "banana", "cherry", "date", "fig",
    "grape", "kiwi", "lemon", "mango", "olive",
    "peach", "pear", "plum", "quince", "berry",
    "melon", "lime", "cocoa", "berry", "leek",
    "corn", "peas", "bean", "kale", "herb",
    "mint", "thyme", "basil", "chive", "dill",
    "onion", "carrot", "radish", "turnip", "squash",
    "salad", "cress", "papaya", "lychee", "guava",
    "tamar", "spice", "aroma", "sugar", "honey",
    "doll", "truck", "train", "ball", "kite",
    "drum", "bear", "ship", "robot", "puzzle",
    "block", "ring", "yoyo", "game", "slide",
    "bike", "racer", "plane", "sword", "castle",
    "crown", "horse", "piano", "flute", "guitar",
    "drone", "skate", "board", "cards", "dart",
    "viola", "harp", "oboe", "claro", "cello",
    "organ", "bongo", "conga", "tabla", "sitar",
    "lyre", "zither", "banjo", "lute", "horn",
    "pen", "pencil", "ruler", "eraser", "clip",
    "glue", "tape", "sharp", "chalk", "marker",
    "brush", "stamp", "ink", "note", "book"
  ];

//   let patternType = getRandomNumber(1, 2); // Start with a simple pattern
  let patternType = getRandomNumber(1, 2); // Start with a simple pattern

  // Adjust patternType based on level to introduce complexity
//   if (level === 5) {
//     patternType = getRandomNumber(1, 2); // Randomly choose between pattern 1 and 2 at level 5
//   } else if (level > 5) {
//     patternType = getRandomNumber(2, 4); // Levels above 5 use patterns 2, 3, or 4
//   }

const selectedWord = words[getRandomNumber(0, words.length - 1)];

  // Generate the correct code for the selected word
  const correctCode = selectedWord.split('').map(letter => letter.charCodeAt(0) - 'a'.charCodeAt(0) + 1).join(' ');

  // Pre-filter words by the selected word's length to avoid repeated filtering
  const sameLengthWords = words.filter(word => word.length === selectedWord.length);

  // More deterministic decoy code generation
  const generateDecoyCodes = (correctCode, numDecoys = 3) => {
    const decoys = new Set();
    const codeNumbers = correctCode.split(' ').map(num => parseInt(num));

    // Generate decoy codes by adding an incremental offset to each number in the correct code
    for (let i = 1; i <= numDecoys; i++) {
      let decoy = codeNumbers.map(num => num + i).join(' ');
      decoys.add(decoy);
    }

    return Array.from(decoys);
  };

  let questionCode, answer, buttonOptions = [];
  switch (patternType) {
    case 1:
      // For patternType 1, use pre-filtered sameLengthWords for decoy generation
      buttonOptions = [selectedWord]; // Initialize with the correct word
      while (buttonOptions.length < 4) {
        const decoyWord = sameLengthWords[getRandomNumber(0, sameLengthWords.length - 1)];
        if (!buttonOptions.includes(decoyWord)) {
          buttonOptions.push(decoyWord);
        }
      }
      buttonOptions = shuffleArray(buttonOptions); // Shuffle to randomize the options
      questionCode = `What is the word for the code "${correctCode}"?`;
      answer = selectedWord;
      break;
    case 2:
      // Use the more deterministic approach for decoy code generation
      const decoyCodes = generateDecoyCodes(correctCode);
      buttonOptions = shuffleArray([correctCode, ...decoyCodes]);
      answer = correctCode;
      questionCode = `What is the code for "${selectedWord}"?`;
      break;
    // Include other cases as necessary
  }

  return {
    questionIntro: "Given the encoding pattern, a=1, b=2, c=3",
    questionCode,
    answer,
    buttonOptions
  };
};