import { getRandomNumber, shuffleArray, getRandomDecimal } from "./utility";

export const generateSequenceQuestion = (level) => {
    const sequenceLength = 5;
    const missingIndex = getRandomNumber(0, sequenceLength - 1);

    let sequence = [];
    let correctAnswer;
    let wrongAnswer1;
    let wrongAnswer2;
    let increment;
    let multiplier;
    let startingNumber = getRandomNumber(1, 10);
    let decimalPrecision = level > 18 ? 1 : 0;

    if (level <= 1) {
        increment = 1;
        multiplier = 1;
    } else if (level <= 3) {
        increment = getRandomNumber(2, 3);
        multiplier = 1;
    } else if (level <= 6) {
        increment = getRandomNumber(1, 5);
        multiplier = 1;
    } else if (level <= 9) {
        increment = getRandomNumber(-5, 5);
        multiplier = 1;
    } else if (level <= 12) {
        increment = getRandomNumber(-5, 5);
        multiplier = level === 12 ? 2 : 1;
    } else if (level <= 15) {
        increment = getRandomNumber(-10, 10);
        multiplier = getRandomNumber(1, 2);
    } else if (level <= 18) {
        increment = getRandomNumber(-10, 10);
        multiplier = getRandomNumber(1, 3);
    } else if (level <= 21) {
        increment = getRandomDecimal(-10, 10, 1);
        multiplier = getRandomNumber(1, 4);
    } else if (level <= 24) {
        increment = getRandomDecimal(-10, 10, 1);
        multiplier = getRandomNumber(1, 5);
    } else if (level <= 27) {
        increment = getRandomDecimal(-15, 15, 1);
        multiplier = getRandomNumber(1, 6);
    } else {
        increment = getRandomDecimal(-20, 20, 2);
        multiplier = getRandomNumber(1, 7);
    }

    let patternType = 1; // Default pattern type
    if (level >= 6) {
        patternType = getRandomNumber(1, 2); // Introduce more complex patterns starting at level 4
    } else if (level >= 12) {
      patternType = getRandomNumber(1, 3); // Introduce more complex patterns starting at level 4
    } else {
      patternType = getRandomNumber(1, 4); // Introduce more complex patterns starting at level 4
    }

    if (patternType === 1) {
        // Arithmetic sequence or other simple pattern
        for (let i = 0; i < sequenceLength; i++) {
            let sequenceValue = (startingNumber + increment * i) * multiplier;
            sequence.push(i === missingIndex ? '_' : sequenceValue.toFixed(decimalPrecision));
        }
        correctAnswer = ((startingNumber + increment * missingIndex) * multiplier).toFixed(decimalPrecision);
    } else if (patternType === 2) {
        // Fibonacci-like pattern
        let first = getRandomNumber(1, 5);
        let second = getRandomNumber(1, 5);
        for (let i = 0; i < sequenceLength; i++) {
            if (i === missingIndex) {
                sequence.push('_');
                continue;
            }
            let sequenceValue = (i < 2) ? (i === 0 ? first : second) : (sequence[i - 1] + sequence[i - 2]);
            sequence.push(sequenceValue);
        }
        correctAnswer = (missingIndex < 2) ? (missingIndex === 0 ? first : second) : (sequence[missingIndex - 1] + sequence[missingIndex - 2]);
    } else if (patternType === 3) {
        // Squared numbers pattern
        for (let i = 0; i < sequenceLength; i++) {
            let sequenceValue = Math.pow((i + 1), 2);
            sequence.push(i === missingIndex ? '_' : sequenceValue);
        }
        correctAnswer = Math.pow((missingIndex + 1), 2);
    } else if (patternType === 4) {
        // Cubed numbers pattern
        for (let i = 0; i < sequenceLength; i++) {
            let sequenceValue = Math.pow((i + 1), 3);
            sequence.push(i === missingIndex ? '_' : sequenceValue);
        }
        correctAnswer = Math.pow((missingIndex + 1), 3);
    }

    wrongAnswer1 = (parseFloat(correctAnswer) + getRandomNumber(1, 3)).toFixed(decimalPrecision);
    wrongAnswer2 = (parseFloat(correctAnswer) - getRandomNumber(1, 3)).toFixed(decimalPrecision);

    while (wrongAnswer1 === correctAnswer || wrongAnswer1 === wrongAnswer2) {
        wrongAnswer1 = (parseFloat(correctAnswer) + getRandomNumber(1, 3)).toFixed(decimalPrecision);
    }
    while (wrongAnswer2 === correctAnswer || wrongAnswer2 === wrongAnswer1) {
        wrongAnswer2 = (parseFloat(correctAnswer) - getRandomNumber(1, 3)).toFixed(decimalPrecision);
    }

    const question = `Complete the sequence: ${sequence.join(', ')}`;

    return {
        question,
        answer: correctAnswer.toString(),
        buttonOptions: shuffleArray([correctAnswer.toString(), wrongAnswer1.toString(), wrongAnswer2.toString()]),
    };
};
