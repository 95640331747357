import { Link, Outlet, useLocation } from "react-router-dom";
import '../App.css';
import { useState, useEffect, useRef } from 'react';
import LoginButton from '../components/LoginButton';
import LogoutButton from '../components/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';

import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';
import LogoSVG from '../images/logo.png';
import { ReactComponent as PlayPauseSVG } from '../images/playPause.svg';
import { ReactComponent as MuteSVG } from '../images/mute.svg';
import { ReactComponent as ContactUsSVG } from '../images/footer/email.svg';
import { ReactComponent as ProfilePageSVG } from '../images/footer/profile.svg';



import FynSVG from '../components/fyn';
import { greetings } from '../components/fyn';
import Overlay from '../components/Overlay';

import anime from 'animejs/lib/anime.es.js';
import { ReactComponent as SunSVG } from '../images/assets/sun.svg';
import { ReactComponent as CloudSVG } from '../images/assets/cloud.svg';
import { startCloudAnimation, animateCloud } from '../animations/cloudAnimation';


const Layout = () => {
  const location = useLocation();
  const [version, setVersion] = useState('');
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, updatePoints, studentName} = useUserData();
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [showTerms, setShowTerms] = useState(false); // State to handle the visibility of Terms and Conditions
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [addedPoints, setAddedPoints] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isDancing, setIsDancing] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const currentSongIndexRef = useRef(null);

  const pageName = "";
  const [customMessage, setCustomMessage] = useState("");
  const customMessages = [
    "Hello, dear guest!",
    "Nice to see you again!",
    "How's your day going?",
    "Ready to explore?",
    "I love my life as a fish!",
    "Hi, I'm Fyn your friendly fish"
  ];

  useEffect(() => {
    // Check if the window.gtag function exists
    if (window.gtag) {
      // Use gtag to send a pageview with the current path
      window.gtag('config', 'G-0TJW2J8529', {
        'page_path': location.pathname + location.search,
      });
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user['https://fynschool.com/roles'];
      // Set the state with the roles
      setUserRoles(roles || []);
      setIsDeveloper(roles && roles.includes('developer'));

      if (roles) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    }
  }, [isAuthenticated, user]);


  useEffect(() => {
      startCloudAnimation();
  }, []);

  useEffect(() => {
    fetch('/Version.txt')
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchUserData(user.sub, user.nickname, user.email);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.volume = 0.1; // Set volume to 10%
    audioElement.addEventListener('ended', handleAudioEnded);

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        const audioElement = audioRef.current;
        audioElement.pause();
      } else if (isPlaying) {
        const audioElement = audioRef.current;
        audioElement.play();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      audioElement.removeEventListener('ended', handleAudioEnded);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const togglePlay = () => {
    const audioElement = audioRef.current;
    if (audioElement.paused) {
      audioElement.play();
      setIsPlaying(true);
    } else {
      audioElement.pause();
      setIsPlaying(false);
    }
  };


  const handleAudioEnded = () => {
    const audioElement = audioRef.current;
    audioElement.currentTime = 0;
    audioElement.play();
  };

  const toggleMute = () => {
    const audioElement = audioRef.current;
    audioElement.muted = !audioElement.muted;
    setIsMute(audioElement.muted); // Update the isMute state
  };

  const closeTerms = () => {
    setShowTerms(false);
  };

  const songs = [
    '/IslandFlex.mp3',
    '/Lovestealing.mp3',
    '/beforeStacy.mp3',
    '/cuba.mp3',
    '/hornting.mp3',
    '/royalFlush.mp3',
  ];

  const getRandomSongIndex = () => {
    let randomIndex;
    do {
        randomIndex = Math.floor(Math.random() * songs.length);
    } while (randomIndex === currentSongIndexRef.current); // Ensure a different song is chosen
    return randomIndex;
};


  const [currentSongIndex, setCurrentSongIndex] = useState(getRandomSongIndex());
  useEffect(() => {
      currentSongIndexRef.current = currentSongIndex;
  }, [currentSongIndex]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('ended', handleSongEnd);
      return () => {
        audio.removeEventListener('ended', handleSongEnd);
      };
    }
  }, []);

  const handleSongEnd = () => {
    setCurrentSongIndex(getRandomSongIndex());
  }

  return (
    <>
      <a href="https://learn.fynschool.com" className="logo-link">
          <img src={LogoSVG} alt="Logo" className="menu-logo" />
      </a>
      <nav className="Nav">
      <button onClick={() => setIsOpen(!isOpen)} className="menu-button">
        {/* Flexbox container */}

        <div className="button-content">
          <span className="menu-text">Menu</span>
        </div>
      </button>

        {isOpen && (
          <ul className={`nav-links ${isOpen ? "open" : ""}`}>
            <li>
              <Link to='/' onClick={() => setIsOpen(false)}>Home</Link>
            </li>
        {isAuthenticated && (userRoles.length > 0) && (
          <>
            <li>
              <Link to='/profilepage' onClick={() => setIsOpen(false)}>Profile</Link>
            </li>
          </>
        )}
            <li>
              <Link to='/dictationdesert' onClick={() => setIsOpen(false)}>Dictation Desert</Link>
            </li>
            <li>
              <Link to='/interviewisland' onClick={() => setIsOpen(false)}>Interview Island</Link>
            </li>
            <li>
              {/* <Link to='/creativecarnival' onClick={() => setIsOpen(false)}>Creative Carnival</Link> */}
              <Link to='/game' state={{ startScene: "CreativeCarnivalScene" }} onClick={() => setIsOpen(false)}>Creative Carnival</Link>
            </li>
            <li>
              <Link to='/comprehensioncounty' onClick={() => setIsOpen(false)}>Comprehension County</Link>
            </li>
            <li>
              <Link to='/spellingsafari' onClick={() => setIsOpen(false)}>Spelling Safari</Link>
            </li>
            <li>
              <Link to='/synonymsea' onClick={() => setIsOpen(false)}>Synonym Sea</Link>
            </li>
            <li>
              {/* <Link to='/mathsmeadow' onClick={() => setIsOpen(false)}>Maths Meadow</Link> */}
              <Link to='/game' state={{ startScene: "MathsMeadowScene" }} onClick={() => setIsOpen(false)}>Maths Meadow</Link>
            </li>
            <li>
              {/* <Link to='/reasoningrainforest' onClick={() => setIsOpen(false)}>Reasoning Rainforest</Link> */}
              <Link to='/game' state={{ startScene: "ReasoningRainforestScene" }} onClick={() => setIsOpen(false)}>Reasoning Rainforest</Link>
            </li>
            <li>
              <Link to='/paperpyramid' onClick={() => setIsOpen(false)}>Paper Pyramid</Link>
            </li>
            <li>
              <Link to='/curriculum' onClick={() => setIsOpen(false)}>Curriculum Page</Link>
            </li>
            {isDeveloper && (
              <>
              <li>
                <Link to='/admin' onClick={() => setIsOpen(false)}>Admin</Link>
              </li>
              <li>
                <Link to='/game' onClick={() => setIsOpen(false)}>Phaser</Link>
              </li>
              </>
            )}
            <li>
              <button onClick={togglePlay} className={`play-pause-button ${isPlaying ? 'playing' : ''}`}>
                <PlayPauseSVG />
              </button>
            </li>
            <li>
              <button onClick={toggleMute} className={`mute-button ${isMute ? 'toMute' : 'unMute'}`}>
              <MuteSVG />
              </button>
            </li>
            {/* <li>
              <button onClick={toggleMute}>
                Mute
              </button>
            </li> */}
            <li className="log-buttons">
              {isAuthenticated ? <LogoutButton /> : <LoginButton />}
            </li>
            <li>
              <span className="version-text">Version: {version}</span>
            </li>
          </ul>
        )}
      </nav>

      <audio ref={audioRef} id="myAudio" src={process.env.PUBLIC_URL + songs[currentSongIndex]} loop />

      <Outlet />
      {userRoles.length === 0 && (
        <div className="App-mid freemode">
          <p>Free Roam Mode</p>
        </div>
      )}

<div className='App-mid'>


{userRoles.length === 0 && (
<div className="arrow-container">
<div className="animated-text">Sign in for points!</div>
<div className="animated-arrow">☝︎</div>
</div>
)}
</div>


  <div className="App-mid footer">
        <button className='terms-button' onClick={() => setShowTerms(true)}>Terms and Conditions</button>
        <div className="footer-svg-container">
          <Link to="/contact" className="footer-svg-link">
            <ContactUsSVG className="footer-svg" />
          </Link>
          <Link to="/profilepage" className="footer-svg-link">
            <ProfilePageSVG className="footer-svg" />
          </Link>
        </div>
      </div>

      {showTerms && (
    <div className="terms-overlay">
      <div className="terms-content">
        <button onClick={closeTerms} className="close-terms">X</button>
        <h2>Terms and Conditions</h2>
        <p>Last Updated: 20 Aug 2023</p>
        <h3>1. Acceptance of Terms</h3>
        <p>By accessing or using our Services, you confirm that you have read, understood, and agree to be bound by these Terms. If you do not agree with any of these terms, you must not use our Services.</p>
        <h3>2. Privacy Policy</h3>
        <p>We are committed to protecting your privacy. Our Privacy Policy, which explains how we collect and use your information, is available at [link to Privacy Policy] and is incorporated into these Terms by reference.</p>
        <h4>2.1 Personal Information</h4>
        <p>We will collect and use personal information such as your name, email address, and points per subject. This information will be used solely for the purpose of providing and improving our Services, tracking your progress, and communicating with you.</p>
        <h3>3. User Registration</h3>
        <p>Users may be required to register an account through Auth0 to access certain features of our Services. You agree to provide accurate and complete information and to keep this information up to date.</p>
        <h3>4. User Conduct</h3>
        <p>You agree to use our Services only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use of the Services.</p>
        <h3>5. Intellectual Property</h3>
        <p>All content, including but not limited to text, images, graphics, and other material contained in our Services, is owned by or licensed to us and is protected by copyright, trademark, and other intellectual property laws.</p>
        <h3>6. Termination</h3>
        <p>We reserve the right to terminate or suspend your access to our Services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to us, other users, or third parties, or for any other reason.</p>
        <h3>7. Disclaimer</h3>
        <p>Our Services are provided "as is" without any warranties of any kind, either express or implied. We make no representations or warranties regarding the accuracy, reliability, or completeness of the content or the Services.</p>
        <h3>8. Limitation of Liability</h3>
        <p>To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use or inability to use our Services.</p>
        <h3>9. Changes to Terms</h3>
        <p>We reserve the right to change these Terms at any time. If we make changes, we will post the revised Terms on our website and update the "Last Updated" date above. Your continued use of our Services after such changes constitutes your acceptance of the new Terms.</p>
        <h3>10. Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us at team@fynschool.com.</p>

      </div>
    </div>
  )}
    </>
  )
}

export default Layout;
