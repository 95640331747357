import BaseScene from "./BaseScene"; // Adjust the path as necessary
import background from "../assets/backgrounds/IntroPage.jpg";
import loginButton from "../assets/buttons/LoginButton.png";
import nextButton from "../assets/buttons/NavButton.png";
import fyn from '../assets/spritesheets/fynSpriteSheet.png'

class WelcomeScene extends BaseScene {
    constructor() {
        super('WelcomeScene');
        this.currentMessageIndex = 0;
    }

    init() {
        // this.registry.set('debug', true);
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        this.load.image('backgroundImage', background);
        this.load.image('loginButton', loginButton);
        this.load.image('nextButton', nextButton);
        this.load.audio('islandFlex', '/IslandFlex.mp3');
        this.load.spritesheet('fyn', fyn, { frameWidth: 801, frameHeight: 2002 })

    }

    create() {
        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');

        this.events.on('resume', this.onResume, this);

        this.environmentGroup = this.add.group();
        if (debug) {
            console.log('Debug is on')
        }
        this.time.delayedCall(100, () => {
            if (this.fynSprite.body) {
                this.resetFynSpriteState();
            } else {
                console.error('Physics body not initialized');
            }
        });


        this.setBackground('backgroundImage'); // Use the key you've defined in preload
        this.backgroundOverlay()

            // Replace this line with tileSprite
        this.floor = this.add.tileSprite(
            this.sys.game.config.width / 2, // X position
            this.sys.game.config.height, // Y position (bottom of the screen)
            this.sys.game.config.width * 24, // Width of the tiled floor
            200, // Height of the floor (you can adjust this as needed)
            'welcomeGround' // Key of the image loaded in preload
        );

        // You no longer need to set the origin, as tileSprite covers the entire area you define
        this.physics.add.existing(this.floor);
        this.floor.body.setImmovable(true);
        this.floor.body.allowGravity = false;
        this.environmentGroup.add(this.floor);

        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const smallFontSize = parseFloat(this.responsiveFontSize(10, 0.02).replace('px', ''));
        const fontSize = this.sys.game.config.width * 0.05;
        const fontSizeSmall = this.sys.game.config.width * 0.02;
        const strokeThickness = this.sys.game.config.width * 0.006;

        const pageBackgroundX = this.sys.game.config.width * -0.05;
        const pageBackgroundY = isPortrait ? this.sys.game.config.height * 0.14 : this.sys.game.config.height * 0.10;
        const pageBackgroundWidth = this.sys.game.config.width * 0.3;
        const pageBackgroundHeight = this.sys.game.config.height * 0.05;
        const pageXPosition = this.sys.game.config.width * 0.15;

        // Create the UI background
        this.createGradientBackground(pageBackgroundX, pageBackgroundY, pageBackgroundWidth, pageBackgroundHeight, 0xFBBC05, 0.8);


        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, `Welcome`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);

        const pointsBackgroundX = isPortrait ? this.sys.game.config.width * 0.65 : this.sys.game.config.width * 0.8;
        const pointsBackgroundY = isPortrait ? this.sys.game.config.height * 0.14 : this.sys.game.config.height * 0.10;
        const pointsBackgroundWidth = this.sys.game.config.width * 0.5;
        const pointsBackgroundHeight = this.sys.game.config.height * 0.07;
        const starYPosition = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.03;
        const starXPosition = isPortrait ? this.sys.game.config.width * 0.85 : this.sys.game.config.width * 0.9;
        const starScale = isPortrait ? 0.1 : 0.2;

        this.add.image(starXPosition, starYPosition, 'pointsStar')
        .setOrigin(0, 0) // Adjust origin as needed, e.g., 0.5 for center
        .setDepth(1000)
        .setScale(starScale);

        // Create the UI background
        this.createGradientBackground(pointsBackgroundX, pointsBackgroundY, pointsBackgroundWidth, pointsBackgroundHeight, 0xFBBC05, 0.8, true);
        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, `Welcome`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);

        this.userData = this.game.registry.get('userData') || {};
        this.game.registry.set('incorrectAnswerMode', false);
        const isAuthenticated = this.game.registry.get('isAuthenticated');
        // const authStatusText = isAuthenticated ? 'I am authenticated!' : 'I am not authenticated.';

        const userRoles = this.registry.get('userRoles')
        // console.log('Phaser roles are:', userRoles)




        const musicButtonXPosition = this.sys.game.config.width * 0.95;
        const musicButtonYPosition = this.sys.game.config.height * 0.2;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.7, // Scale multiplier
            false // Availability
        );



        const cursorLeftButtonXPosition = isPortrait ? this.sys.game.config.width * 0.15 : this.sys.game.config.width * 0.1;
        const cursorRightButtonXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
        const jumpButtonXPosition = isPortrait ? this.sys.game.config.width * 0.8 : this.sys.game.config.width * 0.9;
        const fynButtonsYPosition = this.sys.game.config.height * 0.9;

        const fynsCursorBackgroundX = this.sys.game.config.width * -0.05;
        const fynsJumpButtonBackgroundX = this.sys.game.config.width * 0.85;
        const uiBackgroundWidth = isPortrait ? this.sys.game.config.width * 0.4 : this.sys.game.config.width * 0.3;
        const uiBackgroundHeight = this.sys.game.config.height * 0.07;

        // Create the UI background
        this.createGradientBackground(fynsCursorBackgroundX, fynButtonsYPosition, uiBackgroundWidth, uiBackgroundHeight, 0xFBBC05, 0.8);
        const jumpButtonBackground = this.createGradientBackground(fynsJumpButtonBackgroundX, fynButtonsYPosition, uiBackgroundWidth, uiBackgroundHeight, 0xFBBC05, 0.8,true);


        const buttonDepth = 1000;

        this.leftCursorPressed = false;
        this.rightCursorPressed = false;
        this.jumpButtonPressed = false;

        const leftCursor = this.cursorLeftButton = this.createButton(
            cursorLeftButtonXPosition, // X position
            fynButtonsYPosition, // Y position
            'cursorButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => {
                if (debug) {
                    console.log('Left pressed');
                }
                this.leftCursorPressed = true;
            },
            0.7, // Scale multiplier
            false // Availability
        );
        leftCursor.angle = -180;
        leftCursor.setFlipY(true);
        leftCursor.setDepth(buttonDepth);
        leftCursor.on('pointerdown', () => { this.leftCursorPressed = true; });
        leftCursor.on('pointerup', () => { this.leftCursorPressed = false; });
        leftCursor.on('pointerout', () => { this.leftCursorPressed = false; });


        const rightCursor = this.cursorRightButton = this.createButton(
            cursorRightButtonXPosition, // X position
            fynButtonsYPosition, // Y position
            'cursorButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => { this.rightCursorPressed = true; },
            0.7, // Scale multiplier
            false // Availability
        );
        rightCursor.on('pointerup', () => { this.rightCursorPressed = false; });
        rightCursor.setDepth(buttonDepth);

        this.jumpButton = this.createButton(
            jumpButtonXPosition, // X position
            fynButtonsYPosition, // Y position
            'jumpButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => { this.jumpButtonPressed = true; },
            1, // Scale multiplier
            false // Availability
        ).on('pointerup', () => { this.jumpButtonPressed = false; });
        this.jumpButton.setDepth(buttonDepth);

        const displayName = this.userData && this.userData.studentName ? this.userData.studentName : 'Friend';

        if (isAuthenticated) {
            this.points = this.userData.points ?? 0;
        } else {
            this.game.registry.set('localStorage', true);

            if (!localStorage.getItem('points')) {
                localStorage.setItem('points', 0);
            }

            this.points = localStorage.getItem('points')
        }




        // console.log('points before printing are', this.points)




        if (!this.game.registry.get('musicPlaying')) {
            const music = this.sound.add('islandFlex');
            music.play({
                loop: true,
                volume: 0.1
            });
            this.game.registry.set('musicPlaying', true);
            this.game.registry.set('backgroundMusic', music);
        }

        // // Assuming this.userData.games is an array of game objects
        // let gameCounts = {};

        // // Loop through the games array
        // this.userData.games.forEach(game => {
        //     let gameName = game.gameName;

        //     // Check if the gameName is already in the counter object
        //     if (gameCounts[gameName]) {
        //         gameCounts[gameName]++;
        //     } else {
        //         gameCounts[gameName] = 1;
        //     }
        // });

        // // Log the counts for each gameName
        // for (let gameName in gameCounts) {
        //     console.log(`${gameName}: ${gameCounts[gameName]}`);
        // }



        // Calculate desired button sizes as percentages of screen dimensions
        const targetButtonWidthPercentage = this.sys.game.config.width * 0.1; // Example: 10% of screen width
        const targetButtonHeightPercentage = this.sys.game.config.height * 0.1; // Example: 10% of screen height

        // Calculate scale factors needed to resize the button to the desired sizes
        const scaleWidth = targetButtonWidthPercentage / 150; // 150 is the original width of the button image
        const scaleHeight = targetButtonHeightPercentage / 150; // 150 is the original height of the button image

        // Use the larger of the two scales to ensure visibility in both orientations
        const scale = Math.max(scaleWidth, scaleHeight);


        this.interactiveGroup = this.add.group();


        const pointsXPosition = isPortrait ? this.sys.game.config.width * 0.7 : this.sys.game.config.width * 0.85;
        const pointsYPosition = isPortrait ? this.sys.game.config.height * 0.14 : this.sys.game.config.height * 0.10;


        this.createAnimatedText(pointsXPosition, pointsYPosition, `${this.points}`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }, 10, 0.07).setDepth(1000);

        // Calculate position and size based on screen size
        const xPosition = this.sys.game.config.width * 0.5;
        const yPosition = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.1;



        // Assuming you want the font size to be 5% of the screen width (adjust as needed)

        // Now use these calculated values for your title text
        this.createAnimatedText(
            xPosition, yPosition, 'Fynschool', {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#ffff00', // Yellow color
                stroke: '#000000',
                strokeThickness: strokeThickness*2,
                shadow: { offsetX: 2, offsetY: 2, color: '#000', blur: 8, fill: true }
            }, 10, 0.16);

            // Retrieve authentication status from Phaser's registry


            if (!isAuthenticated) {
                this.game.registry.set('localStorage', true);

                if (!localStorage.getItem('points')) {
                    localStorage.setItem('points', 0);
                }

                this.points = localStorage.getItem('points')

                const loginXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.7;
                const loginYPosition = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.1;
                const loginScale = isPortrait ? 2 : 1.2;


                this.time.delayedCall(500, () => {
                    const loginButton = this.createButton(
                        loginXPosition,
                        loginYPosition,
                        'loginButton',
                        null,
                        () => window.dispatchEvent(new CustomEvent('triggerLogin')),
                        loginScale
                    );
                    // this.environmentGroup.add(loginButton)
                });

            }


            const nextXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.96;
            const nextYPosition = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.95;
            const nextScale = isPortrait ? 2 : 0.5;


            this.time.delayedCall(500, () => {
                const nextButton = this.createButton(
                    nextXPosition,
                    nextYPosition,
                    'nextButton',
                    null,
                    () => {
                        this.scene.stop('WelcomeScene');
                        this.scene.start('MenuScene');
                    },
                    nextScale
                );
                // this.environmentGroup.add(loginButton)
            });

            //this is the messages cycling on welcome page
            // else {

            //     // this is when we have a logged in user
            //     const width = this.cameras.main.width;
            //     const height = this.cameras.main.height;



            //     // last logged in
            //     const lastGameDateTime = this.userData.games?.[this.userData.games.length - 1]?.dateTime || null;

            //     // console.log('Displaying the last log in', lastGameDateTime)

            //     // const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
            //     const buttonWidth = this.sys.game.config.width / 8;
            //     const buttonHeight = buttonWidth / 2;

            //     const buttonScale = isPortrait ? 1.5 : 2;


            //     let messageFontSize = isPortrait ? buttonHeight / 1 : buttonHeight / 2;
            //     let message = 'Welcome! Ready for an adventure?';

            //     let textObject = this.add.text(width / 2, height / 2, message, {
            //         font: `${messageFontSize}px gaegu`,
            //         color: '#f78737',
            //         stroke: '#000000',
            //         strokeThickness: strokeThickness,
            //         align: 'center',
            //         wordWrap: { width: width - 40 }
            //     }).setOrigin(0.5, 0.5);

            //     // Check if lastGameDateTime is not null
            //     if (lastGameDateTime) {
            //         // Parse the dateTime string into a Date object
            //         const lastGameDate = new Date(lastGameDateTime);
            //         // Get the current date
            //         const currentDate = new Date();

            //         // Calculate the difference in time (milliseconds)
            //         const diffTime = currentDate - lastGameDate;
            //         // Convert the difference from milliseconds to days
            //         const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            //         // Log the result
            //         // console.log(`Last game was ${diffDays} day(s) ago`);
            //         if (diffDays < 4) {
            //             message = "You're on a roll! Keep up the great work!";
            //           } else if (diffDays < 14) {
            //             message = "Great to see you back! Ready for another adventure?";
            //           } else {
            //             message = "Long time no see! Let's jump back in and have some fun!";
            //           }

            //           textObject.setText(message);


            //     } else {
            //         console.log("No games found");
            //     }

            //     const uniqueIncorrectAnswersCount = this.collectIncorrectAnswers(this.userData);

            //     // you want to try the incorrect questions?

            //     const messages = [
            //         { text: `Check incorrect answers? (${uniqueIncorrectAnswersCount} left)`, action: this.loadIncorrectAnswersScene.bind(this) },
            //         { text: 'Practice Maths?', action: this.loadMathPracticeScene.bind(this) },
            //         { text: 'Practice Creative Writing?', action: this.loadCreativePracticeScene.bind(this) }
            //       ];

            //       if (uniqueIncorrectAnswersCount === 0) {
            //         messages.shift();
            //     }

            //       // Change the message after 3 seconds
            //         this.time.delayedCall(3000, () => {
            //             this.startMessageCycling(textObject, messages);
            //         });



            //     // Calculate position for the login message
            //     const loginMessageX = this.sys.game.config.width / 2;
            //     const loginMessageY = this.sys.game.config.height *0.9;

            //     // Assuming createAnimatedText is a method you've defined that animates text
            //     this.createAnimatedText(loginMessageX, loginMessageY, 'Continue to all topics...', {
            //         fontFamily: 'gaegu',
            //         fontSize: `${fontSizeSmall}px`,
            //         color: '#f78737',
            //         stroke: '#000000',
            //         strokeThickness: strokeThickness,
            //         align: 'center'
            //     }, 10, 0.04) // Center the text

            //     // Add an interactive login button
            //     this.nextButton = this.createButton(
            //         this.sys.game.config.width * 0.7,
            //         this.sys.game.config.height * 0.9,
            //         'nextButton',
            //         null,
            //         // () => window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/Mathsmeadow' } }))
            //         () => {
            //             // Switch to the MenuPage scene within Phaser
            //             this.scene.start('MenuScene');
            //         }
            //     );
            //     this.environmentGroup.add(this.nextButton)
            // }

            // Page 1


            let leftcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 0.08;
            let rightcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 0.62;
            let leftcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.05);
            let leftcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.1);
            let rightcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.65);
            let rightcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.6);
            let firstGradientRowY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.27;
            let firstRowY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.27;
            // Change the multiplier to increase size
            let firstRow2Y = isPortrait ? this.sys.game.config.height * 0.1 : (3 * pageBackgroundHeight) + firstGradientRowY;
            let secondRowY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.65;

            // checking userdata

            if (debug) {
                console.log('Userdata: ', this.userData)
            }

            // Progress
            const progressWidth = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
            const progressTextHeight = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.25;
            const progressG = this.createGradientBackground(leftcolumnX, firstRowY, progressWidth, pageBackgroundHeight, 0xFBBC05, 0.8);
            const progress2G = this.createGradientBackground(leftcolumnTextX, firstRow2Y, progressWidth, progressTextHeight, 0xFBBC05, 0.8).setDepth(4);
            const progressT = this.createAnimatedText(leftcolumnTextX, firstRowY, `Progress`, {
                fontSize: `${baseFontSize}px`, color: '#f78737', stroke: '#000000', strokeThickness: strokeThickness, align: 'left'
            }).setDepth(1000);
            const progress2T = this.createAnimatedText(leftcolumnText2X, firstRow2Y,
                    `Maths:
                    English:
                    Reasoning:
                    Comprehension:
                    Spelling:
                    `,
            {
                fontFamily: 'gaegu',
                fontSize: `${smallFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness*0.5,
                align: 'left'
            }).setDepth(1000);
            this.environmentGroup.add(progressG)
            this.environmentGroup.add(progress2G)
            this.environmentGroup.add(progressT)
            this.environmentGroup.add(progress2T)

            // Quick Links
            const quickLinksWidth = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
            const quickLinksTextHeight = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.25;
            const quickLinksIconX = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
            const quickLinksG = this.createGradientBackground(rightcolumnX, firstRowY, progressWidth, pageBackgroundHeight, 0xFBBC05, 0.8,true);
            let linkXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.8;

            let linkButtonScale = isPortrait ? 1 : 1;

            // const quickLinks2G = this.createGradientBackground(rightcolumnTextX, firstRow2Y, progressWidth, progressTextHeight, 0xFBBC05, 0.8).setDepth(4);
            const quickLinksT = this.createAnimatedText(rightcolumnTextX, firstRowY, `Quick Links`, {
                fontSize: `${baseFontSize}px`, color: '#f78737', stroke: '#000000', strokeThickness: strokeThickness, align: 'left'
            }).setDepth(1000);
            const quickLinks2T = this.createAnimatedText(rightcolumnText2X, firstRow2Y,
                    `Maths Meadow`,
            {
                fontSize: `${smallFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness*0.5,
                align: 'left'
            }).setDepth(1000);
            const mathsButton = this.createButton(
                linkXPosition, firstRow2Y,
                'mmButton',
                null,
                () => this.scene.start('MathsMeadowScene'),
                linkButtonScale
            );
            const quickLinks3T = this.createAnimatedText(rightcolumnText2X, secondRowY,
                    `Creative Carnival`,
            {
                fontSize: `${smallFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness*0.5,
                align: 'left'
            }).setDepth(1000);
            const creativeButton = this.createButton(
                linkXPosition, secondRowY,
                'ccButton',
                null,
                () => this.scene.start('CreativeCarnivalScene'),
                linkButtonScale
            );

            this.environmentGroup.add(quickLinksG)
            this.environmentGroup.add(quickLinksT)
            this.environmentGroup.add(quickLinks2T)
            this.environmentGroup.add(mathsButton)
            this.environmentGroup.add(quickLinks3T)
            this.environmentGroup.add(creativeButton)

            // Page 2
            leftcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 1.08;
            rightcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 1.62;
            leftcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.05);
            leftcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.1);
            rightcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.65);
            rightcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.6);
            linkXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 1.5;
            linkButtonScale = isPortrait ? 1 : 2;

            // maths
            const mathsWidth = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
            const mathsTextHeight = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.25;
            const mathsG = this.createGradientBackground(leftcolumnX, firstRowY, mathsWidth, pageBackgroundHeight, 0xFBBC05, 0.8);
            const maths2G = this.createGradientBackground(leftcolumnTextX, firstRow2Y, mathsWidth, mathsTextHeight, 0xFBBC05, 0.8).setDepth(4);
            const mathsT = this.createAnimatedText(leftcolumnTextX, firstRowY, `Maths`, {
                fontSize: `${baseFontSize}px`, color: '#f78737', stroke: '#000000', strokeThickness: strokeThickness, align: 'left'
            }).setDepth(1000);
            const maths2T = this.createAnimatedText(leftcolumnText2X, firstRow2Y,
                    `Maths:
                    English:
                    Reasoning:
                    Comprehension:
                    Spelling:
                    `,
            {
                fontFamily: 'gaegu',
                fontSize: `${smallFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness*0.5,
                align: 'left'
            }).setDepth(1000);

            const mathsMButton = this.createButton(
                linkXPosition, firstRow2Y,
                'mmButton',
                null,
                () => this.scene.start('MathsMeadowScene'),
                linkButtonScale
            );
            this.environmentGroup.add(mathsG)
            this.environmentGroup.add(maths2G)
            this.environmentGroup.add(mathsT)
            this.environmentGroup.add(maths2T)
            this.environmentGroup.add(mathsMButton)

            // Page 3
            leftcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 2.08;
            rightcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 2.62;
            leftcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.05);
            leftcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.1);
            rightcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.65);
            rightcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.6);
            linkXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 2.5;
            linkButtonScale = isPortrait ? 1 : 2;

            // creative
            const creativeWidth = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
            const creativeTextHeight = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.25;
            const creativeG = this.createGradientBackground(leftcolumnX, firstRowY, creativeWidth, pageBackgroundHeight, 0xFBBC05, 0.8);
            const creative2G = this.createGradientBackground(leftcolumnTextX, firstRow2Y, creativeWidth, creativeTextHeight, 0xFBBC05, 0.8).setDepth(4);
            const creativeT = this.createAnimatedText(leftcolumnTextX, firstRowY, `Creative Writing`, {
                fontSize: `${baseFontSize}px`, color: '#f78737', stroke: '#000000', strokeThickness: strokeThickness, align: 'left'
            }).setDepth(1000);
            const creative2T = this.createAnimatedText(leftcolumnText2X, firstRow2Y,
                    `Maths:
                    English:
                    Reasoning:
                    Comprehension:
                    Spelling:
                    `,
            {
                fontFamily: 'gaegu',
                fontSize: `${smallFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness*0.5,
                align: 'left'
            }).setDepth(1000);

            const creativeMButton = this.createButton(
                linkXPosition, firstRow2Y,
                'ccButton',
                null,
                () => this.scene.start('CreativeCarnivalScene'),
                linkButtonScale
            );
            this.environmentGroup.add(creativeG)
            this.environmentGroup.add(creative2G)
            this.environmentGroup.add(creativeT)
            this.environmentGroup.add(creative2T)
            this.environmentGroup.add(creativeMButton)

            // Page 3
            leftcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 3.08;
            rightcolumnX = isPortrait ? this.sys.game.config.width * -0.05 : this.sys.game.config.width * 3.62;
            leftcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.05);
            leftcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.1);
            rightcolumnTextX = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.65);
            rightcolumnText2X = isPortrait ? this.sys.game.config.width * -0.05 : (leftcolumnX + this.sys.game.config.width * 0.6);
            linkXPosition = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 3.5;
            linkButtonScale = isPortrait ? 1 : 2;

            // reasoning
            const reasoningWidth = isPortrait ? this.sys.game.config.width * 0.3 : this.sys.game.config.width * 0.2;
            const reasoningTextHeight = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.25;
            const reasoningG = this.createGradientBackground(leftcolumnX, firstRowY, reasoningWidth, pageBackgroundHeight, 0xFBBC05, 0.8);
            const reasoning2G = this.createGradientBackground(leftcolumnTextX, firstRow2Y, reasoningWidth, reasoningTextHeight, 0xFBBC05, 0.8).setDepth(4);
            const reasoningT = this.createAnimatedText(leftcolumnTextX, firstRowY, `Reasoning`, {
                fontSize: `${baseFontSize}px`, color: '#f78737', stroke: '#000000', strokeThickness: strokeThickness, align: 'left'
            }).setDepth(1000);
            const reasoning2T = this.createAnimatedText(leftcolumnText2X, firstRow2Y,
                    `Maths:
                    English:
                    Reasoning:
                    Comprehension:
                    Spelling:
                    `,
            {
                fontFamily: 'gaegu',
                fontSize: `${smallFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness*0.5,
                align: 'left'
            }).setDepth(1000);

            const reasoningMButton = this.createButton(
                linkXPosition, firstRow2Y,
                'rrButton',
                null,
                () => this.scene.start('ReasoningRainforestScene'),
                linkButtonScale
            );
            this.environmentGroup.add(reasoningG)
            this.environmentGroup.add(reasoning2G)
            this.environmentGroup.add(reasoningT)
            this.environmentGroup.add(reasoning2T)
            this.environmentGroup.add(reasoningMButton)





            this.fynSprite = this.physics.add.sprite(this.sys.game.config.width / 10, this.sys.game.config.height / 2, "fyn").setOrigin(0);
            // const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
            const spriteScale = isPortrait ? 0.13 : 0.15;

            this.fynSprite.body.setVelocity(0, 0);  // Reset velocity to 0
            this.fynSprite.body.setAcceleration(0, 0);
            this.fynSprite.anims.play('idle', true);  // Play idle animation
            this.fynSprite.setFlipX(false);

            this.fynSprite.setScale(spriteScale);
            this.fynSprite.body.gravity.y = 600;
            this.fynSprite.body.bounce.y = 0.6;
            // Set the world bounds to be wider than the game screen
            this.physics.world.setBounds(0, 0, this.sys.game.config.width * 2, this.sys.game.config.height);

            this.fynSprite.setCollideWorldBounds(false);

            this.physics.add.collider(this.fynSprite, this.floor);
            this.fynSprite.body.setDrag(400, 0); // Apply drag for natural deceleration
            this.fynSprite.body.setMaxVelocity(400, 400); // Set max velocity for x and y
            this.fynSprite.setDepth(10);
            this.fynSprite.preFX.setPadding(32);
            const fynGlow = this.fynSprite.preFX.addGlow();  // Add the glow effect

            // Optionally animate the glow
            this.tweens.add({
                targets: fynGlow,
                outerStrength: 10,
                yoyo: true,
                loop: -1,
                ease: 'sine.inout'
            });

            this.anims.create({
                key: 'left',
                frames: this.anims.generateFrameNumbers('fyn', { start: 0, end: 7 }),
                frameRate: 20,
                repeat: -1
            });

            this.anims.create({
                key: 'right',
                frames: this.anims.generateFrameNumbers('fyn', { start: 0, end: 7 }),
                frameRate: 20,
                repeat: -1
            });

            this.anims.create({
                key: 'idle',
                frames: this.anims.generateFrameNumbers('fyn', { start: 8, end: 9 }),
                frameRate: 2
            });


            // Initialize the isJumping flag
            this.isJumping = false;

            this.fynSprite.setCollideWorldBounds(true);

            // Input setup

            this.messages = [
                "Hello there!",
                "You look great today!",
                "Ready to learn something new?",
                "Nice to see you again!",
                "How's your day going?",
                "Ready to explore?",
                "I love my splish splash fish life!",
                "Hi, I'm Fyn your friendly fish",
                "I'm so fish-tastic, I forgot how to swim! Just kidding 🐟",
                "Hi, I'm Fyn, your friendly fish. Don't worry, I won't clam up on you!",
                "Why did the fish blush? Because it saw the ocean's bottom! 🐠",
                "I'm swimming in knowledge, want to dive in?",
                "You know what's my favorite school? Fish School!",
                "I've got a joke for you! What do you call a fish with no eyes? Fsh!",
                "Feeling Fyntastic today, how about you?",
                "Let's make a splash in learning!",
                "They say there are plenty of fish in the sea, but none like me!",
                "I'm all about that bass, no treble!",
                "I'm a fish out of water when it comes to dry humor!",
                "Why did the fish get kicked out of school? Because he was always swimming below 'C' level!",
                "I'm so good at swimming, I've got my Ph.D. in Fish-ics!",
                "Why don't fish do well in school? Because they're always swimming below the 'C'!",
                "I told my friend I was feeling a bit 'gill-ty' today. Get it? 🐟",
                "My friend is not a selfish fish, He's a shellfish!",
                "Why did the fish always know how much it weighed? Because it had its own scales!",
                "I'm so fish-tastic, I've got my own 'stream'ing service!",
                "Ready to tackle today's lessons fin-first?",
                "Let's dive deep into today's study material!",
                "Swim against the current, and you'll find knowledge!",
                "Don't flounder around, let's get studying!",
                "Just keep swimming through those books!",
                "Let's make today's study session a real 'splash'!",
                "You're o-fish-ally awesome at learning!",
                "Let's reel in some knowledge today!",
                "Don't be koi, ask me anything you want to know!",
                "You're swimmingly good at this subject!",
                "What's a fish's favorite instrument? The bass guitar!",
                "Why did the fish get bad grades? Because he was below sea level!",
                "What's a fish's favorite country? Finland!",
                "Why did the fish blush? Because it saw the sea-weed!",
                "What's a fish's favorite game? Go Fish!",
                "Why did the fish stay in school? To avoid the net!",
                "Why did the fish refuse to play cards? Because he was afraid of the net!",
                "What's a fish's favorite TV show? Whale of Fortune!",
                "Click on me and I'll try to help!",
                "Why don't fish play football? Because they are afraid of the nets!",
                "What happens when you annoy a pufferfish? It gets a little 'puffed' up!",
                "Hello",
                "Are you okay?",
                "What's up?",
                "Hi there!",
                "Greetings and salutations!",
                "Hello, sunshine!",
                "Hey, howdy, hey!",
                "Warmest welcome!",
                "Hola, amigo!",
                "How's it going?",
                "What's up, buttercup?",
                "Top of the morning to you!",
                "Bonjour, mon ami!",
                "G'day, mate!",
                "Aloha, everyone!",
                "Salut, my friend!",
                "Hey, good-looking!",
                "Yo, what's cracking?",
                "Howdy-doody!",
                "Wassup, champ?",
                "Hi, lovely people!",
                "Greetings, Humans!",
            ];


            this.cursors = this.input.keyboard.createCursorKeys();
            this.setupSwipeControls();

            this.fynSprite.setInteractive();
            // Function to show a speech bubble with a random message
            this.showRandomMessage = () => {
                const randomMessage = this.messages[Math.floor(Math.random() * this.messages.length)];
                const { bubble, content } = this.createSpeechBubble(this.fynSprite.x + 60, this.fynSprite.y - 50, randomMessage);

                this.time.delayedCall(4000, () => {
                    bubble.destroy();
                    content.destroy();
                });

                // Store reference to bubble and content for updating position in update loop
                this.speechBubble = { bubble, content };

            };

            // Add pointerdown event listener
            this.fynSprite.on('pointerdown', this.showRandomMessage);

            // Create a timed event that calls showRandomMessage every few seconds
            this.time.addEvent({
                delay: Phaser.Math.Between(20000, 40000), // Random delay between 20 and 40 seconds
                callback: this.showRandomMessage,
                callbackScope: this,
                loop: true
            });

            // console.log('userSub in welcome', this.game.registry.get('userSub'))

    }

    update() {
        const useLocalStorage = this.game.registry.get('localStorage');

        if (this.points !== this.userData.points) {
            this.points = this.userData.points;
        } else if (useLocalStorage) {
            const localStoragePoints = parseInt(localStorage.getItem('points'), 10);
            if (this.points !== localStoragePoints) {
                this.points = localStoragePoints;
            }
        }

        // Parallax background effect
        const parallaxFactorX = 0.0005;  // Adjust this value to control the intensity of the parallax effect horizontally
        const parallaxFactorY = 0.00025; // Adjust this value to control the intensity of the parallax effect vertically

        // Adjust the background position based on input instead of Fyn's velocity
        if (this.cursors.left.isDown || this.leftCursorPressed) {
            this.background.x += 2.5 * parallaxFactorX;
        } else if (this.cursors.right.isDown || this.rightCursorPressed) {
            this.background.x -= 2.5 * parallaxFactorX;
        }

        // Ensure the background stays within the desired bounds (optional, depending on your design)
        const bgX = this.sys.game.config.width / 2;
        const bgY = this.sys.game.config.height / 2;
        // this.background.x = Phaser.Math.Clamp(this.background.x, bgX - 100, bgX + 100);  // Adjust the clamp values as needed
        // this.background.y = Phaser.Math.Clamp(this.background.y, bgY - 50, bgY + 50);    // Adjust the clamp values as needed

        // Check if Fyn is on the ground to potentially reset the isJumping flag and adjust animations
        if (this.fynSprite.body.touching.down || this.fynSprite.body.blocked.down) {

            // Decide which animation to play based on input
            if (this.cursors.left.isDown  || this.leftCursorPressed) {
                this.fynSprite.anims.play('left', true);
                this.fynSprite.setFlipX(true);
                this.environmentGroup.children.iterate((child) => {
                    child.x += 5;
                });
                this.background.x += 0.25;
            } else if (this.cursors.right.isDown  || this.rightCursorPressed) {
                this.fynSprite.anims.play('right', true);
                this.fynSprite.setFlipX(false);
                this.environmentGroup.children.iterate((child) => {
                    child.x -= 5;
                });
                this.background.x -= 0.25;
            } else {
                if (this.fynSprite.body.velocity.x !== 0) {
                    const deceleration = 0.9; // Adjust this value for a smoother stop
                    this.environmentGroup.children.iterate((child) => {
                        child.x -= this.fynSprite.body.velocity.x * 0.0025 * deceleration;
                    });
                    this.background.tilePositionX -= this.fynSprite.body.velocity.x * 0.00125 * deceleration;
                }
                this.fynSprite.anims.play('idle', true);
            }
            this.isJumping = false;
        }

        // Handle jumping
        this.spaceBar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        if ((this.cursors.up.isDown || this.jumpButtonPressed || this.spaceBar.isDown) && !this.isJumping && (this.fynSprite.body.touching.down || this.fynSprite.body.blocked.down)) {
            this.isJumping = true;
            this.fynSprite.body.setVelocityY(-800);
            this.fynSprite.anims.stop();
            this.fynSprite.setTexture('fyn', 10);
        }

        // Move the environment during the jump
        if (this.isJumping) {
            if (this.cursors.left.isDown || this.leftCursorPressed) {
                this.environmentGroup.children.iterate((child) => {
                    child.x += 5;
                });
                this.background.tilePositionX += 2.5;
            } else if (this.cursors.right.isDown || this.rightCursorPressed) {
                this.environmentGroup.children.iterate((child) => {
                    child.x -= 5;
                });
                this.background.tilePositionX -= 2.5;
            }
        }


        const uniqueIncorrectAnswersCount = this.collectIncorrectAnswers(this.userData);

        // You want to try the incorrect questions?
        const messages = [
            { text: `Check incorrect answers? (${uniqueIncorrectAnswersCount} left)`, action: this.loadIncorrectAnswersScene.bind(this) },
            { text: 'Practice Maths?', action: this.loadMathPracticeScene.bind(this) },
            { text: 'Practice Creative Writing?', action: this.loadCreativePracticeScene.bind(this) }
        ];

        if (uniqueIncorrectAnswersCount === 0) {
            messages.shift();
        }
    }



    setupSwipeControls() {
        let swipeCoordX = 0;
        let swipeCoordY = 0;
        let swipeCoordX2 = 0;
        let swipeCoordY2 = 0;
        let swipeMinDistance = 50;

        this.input.on('pointerdown', (pointer) => {
            swipeCoordX = pointer.downX;
            swipeCoordY = pointer.downY;
        });

        this.input.on('pointerup', (pointer) => {
            swipeCoordX2 = pointer.upX;
            swipeCoordY2 = pointer.upY;

            let deltaX = swipeCoordX2 - swipeCoordX;
            let deltaY = swipeCoordY2 - swipeCoordY;

            if (Math.abs(deltaX) > swipeMinDistance) {
                if (deltaX > 0) {
                    this.fynSprite.body.setAccelerationX(1400); // Swipe right
                    this.fynSprite.anims.play('right', true);
                    this.fynSprite.setFlipX(false);
                } else {
                    this.fynSprite.body.setAccelerationX(-1400); // Swipe left
                    this.fynSprite.anims.play('left', true);
                    this.fynSprite.setFlipX(true);
                }
            } else if (Math.abs(deltaY) > swipeMinDistance) {
                if (deltaY < 0 && !this.isJumping && (this.fynSprite.body.touching.down || this.fynSprite.body.blocked.down)) {
                    this.isJumping = true;
                    this.fynSprite.body.setVelocityY(-800); // Swipe up for jump
                    this.fynSprite.anims.stop();
                    this.fynSprite.setTexture('fyn', 10);
                }
            }
        });
    }


    startMessageCycling(textObject, messages) {
        // Set the initial message immediately
        if (messages[0] && messages[0].text) {
            textObject.setText(messages[0].text);
        }

        // Add a time event to cycle through the messages
        this.time.addEvent({
            delay: 3000,
            callback: () => {
                this.currentMessageIndex = (this.currentMessageIndex + 1) % messages.length;
                this.tweens.add({
                    targets: textObject,
                    alpha: 0,
                    duration: 500,
                    onComplete: () => {
                        if (textObject) { // Ensure textObject is defined
                            textObject.setText(messages[this.currentMessageIndex].text);
                            this.tweens.add({
                                targets: textObject,
                                alpha: 1,
                                duration: 500
                            });
                        }
                    }
                });
            },
            loop: true
        });

        // Create button for cycling messages
        this.nextButton = this.createButton(
            this.sys.game.config.width * 0.5,
            this.sys.game.config.height * 0.7,
            'nextButton',
            null,
            () => {
                if (messages[this.currentMessageIndex] && messages[this.currentMessageIndex].action) {
                    messages[this.currentMessageIndex].action();
                }
            }
        );
        this.environmentGroup.add(this.nextButton)
    }



    createSpeechBubble(x, y, quote) {
        const bubblePadding = 15;

        // Create the text object
        const content = this.add.text(0, 0, quote, {
            fontFamily: 'Arial',
            fontSize: 20,
            color: '#000000',
            align: 'center',
            wordWrap: { width: 300 } // Set a maximum width for word wrapping
        });

        const textBounds = content.getBounds();

        // Calculate bubble dimensions based on text size and padding
        const bubbleWidth = textBounds.width + bubblePadding * 2;
        const bubbleHeight = textBounds.height + bubblePadding * 2;
        const arrowHeight = bubbleHeight / 4;

        const bubble = this.add.graphics({ x: x, y: y });

        // Bubble shadow
        bubble.fillStyle(0x222222, 0.5);
        bubble.fillRoundedRect(6, 6, bubbleWidth, bubbleHeight, 16);

        // Bubble color
        bubble.fillStyle(0xffffff, 1);

        // Bubble outline line style
        bubble.lineStyle(4, 0x565656, 1);

        // Bubble shape and outline
        bubble.strokeRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);
        bubble.fillRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);

        // Calculate arrow coordinates
        const point1X = Math.floor(bubbleWidth / 7);
        const point1Y = bubbleHeight;
        const point2X = Math.floor((bubbleWidth / 7) * 2);
        const point2Y = bubbleHeight;
        const point3X = Math.floor(bubbleWidth / 7);
        const point3Y = Math.floor(bubbleHeight + arrowHeight);

        // Bubble arrow shadow
        bubble.lineStyle(4, 0x222222, 0.5);
        bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);

        // Bubble arrow fill
        bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
        bubble.lineStyle(2, 0x565656, 1);
        bubble.lineBetween(point2X, point2Y, point3X, point3Y);
        bubble.lineBetween(point1X, point1Y, point3X, point3Y);
        bubble.setDepth(1009);

        // Set the position of the text object within the bubble
        content.setPosition(x + bubblePadding, y + bubblePadding);

        content.setDepth(1010);

        // Return both bubble and text so they can be managed together
        return { bubble, content };
    }


    loadIncorrectAnswersScene() {
        // console.log('Loading Incorrect Answers Scene...');
        this.game.registry.set('incorrectAnswerMode', true);
        this.scene.start('LoadingScene', {
            topic: 'incorrectAnswerMode',
            level: 1
        });

    }

    loadMathPracticeScene() {
        // console.log('Loading Math Practice Scene...');
        this.scene.start('MathsMeadowScene');
    }

    loadCreativePracticeScene() {
        // console.log('Loading Math Practice Scene...');
        this.scene.start('CreativeCarnivalScene');
    }

    loadSpellingPracticeScene() {
        console.log('Loading Spelling Practice Scene...');
        // Code to switch to the spelling practice scene
    }

    onResume() {
        // Reset sprite's state when the scene is resumed
        console.log('scene resumed')
        this.resetFynSpriteState();
    }

    resetFynSpriteState() {
        // Ensure velocity, acceleration, drag, and position are reset
        this.fynSprite.body.setVelocity(0, 0);   // Reset velocity to 0
        this.fynSprite.body.setAcceleration(0, 0);  // Reset acceleration
        this.fynSprite.body.setDrag(0, 0);  // Reset drag
        this.fynSprite.setPosition(this.sys.game.config.width / 10, this.sys.game.config.height / 2);  // Reset position if necessary
        this.fynSprite.anims.play('idle', true);  // Play idle animation to reset animation state
    }

    collectIncorrectAnswers(userData) {
        if (!userData || typeof userData !== 'object') {
            console.error('Invalid userData');
            return 0;
        }

        const questionSet = new Set();

        Object.keys(userData).forEach(key => {
            const section = userData[key];
            if (section && Array.isArray(section.incorrectAnswers)) {
                section.incorrectAnswers.forEach(incorrectAnswer => {
                    if (incorrectAnswer.questionScene) {
                        const transformedIncorrectAnswer = {
                            question: incorrectAnswer.questionText,
                            answer: incorrectAnswer.correctAnswer,
                            scene: incorrectAnswer.questionScene
                        };

                        // Create a unique identifier for each question
                        const questionIdentifier = JSON.stringify(transformedIncorrectAnswer);

                        // Add to the set to ensure no duplicates
                        questionSet.add(questionIdentifier);
                    }
                });
            }
        });

        // Count the unique incorrect answers
        const uniqueIncorrectAnswersCount = questionSet.size;

        // Log or display the count if it's more than 0
        if (uniqueIncorrectAnswersCount > 0) {
            // console.log('Number of unique incorrect answers:', uniqueIncorrectAnswersCount);
        }

        return uniqueIncorrectAnswersCount;
    }
}




export default WelcomeScene;
