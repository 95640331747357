import BaseScene from "./BaseScene"; // Adjust the path as necessary
import gsBackground from "../assets/backgrounds/GameSummary.jpg";
import loginButton from "../assets/buttons/LoginButton.png";
class GameSummaryScene extends BaseScene {
    constructor() {
        super('GameSummaryScene');
        this.currentAnswerIndex = 0;
        this.textGroup = null;
    }

    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        this.load.image('gsBackgroundImage', gsBackground); // Note the key 'backgroundImage' here
        this.load.image('loginButton', loginButton);

    }

    create() {
        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');
        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;

        this.setBackground('gsBackgroundImage'); // Set the background image
        this.backgroundOverlay()
        const isAuthenticated = this.game.registry.get('isAuthenticated');
        let userData = this.game.registry.get('userData') || { points: 0 };
        const score = this.registry.get('score') || 0;
        const points = this.registry.get('points') || 0;
        const correctCount = this.registry.get('correctCount') || 0;

        const musicButtonXPosition = this.sys.game.config.width * 0.95;
        const musicButtonYPosition = this.sys.game.config.height * 0.2;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );
        this.textGroup = this.add.group();


        const pageBackgroundX = this.sys.game.config.width * -0.05;
        const pageBackgroundY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.10;
        const pageBackgroundWidth = this.sys.game.config.width * 0.3;
        const pageBackgroundHeight = this.sys.game.config.height * 0.05;
        const pageXPosition = this.sys.game.config.width * 0.15;

        // Create the UI background
        this.createGradientBackground(pageBackgroundX, pageBackgroundY, pageBackgroundWidth, pageBackgroundHeight, 0xFBBC05, 0.8);


        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, `Game Summary`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);


        const incorrectAnswers = this.registry.get('incorrectAnswers') || [];
        const userSub = this.game.registry.get('userSub');
        const gameName = this.game.registry.get('gameName');

        this.displayCurrentIncorrectAnswer();  // Initially display the first incorrect answer
        this.createNavigationButtons();

        // Logging data to verify before dispatching the event
        // console.log("Authenticated:", isAuthenticated);
        // console.log("Score:", score);
        // console.log("Incorrect Answers:", incorrectAnswers);
        // console.log("User Sub:", userSub);
        // console.log("Game Name:", gameName);

        this.registry.set('score', 0);
        // console.log('parent scene', this.game.registry.get('parentScene') )

        const parentScene = this.game.registry.get('parentScene') || 'WelcomeScene';

        // Create a back button
        const backButtonXPosition = isPortrait? this.sys.game.config.width * 0.05 : this.sys.game.config.width * 0.05;
        const backButtonYPosition = isPortrait ? this.sys.game.config.height * 0.15 : this.sys.game.config.height * 0.2;
        const backButtonScale = isPortrait ? 0.5 : 1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => {
            this.clearGameData();
            this.scene.start(parentScene),
            backButtonScale;
        });

        // Define the position for the message
        const messagesXposition = isPortrait ? this.sys.game.config.width * 0.4 : this.sys.game.config.width * 0.4;
        const messageXPosition = this.sys.game.config.width / 2;
        const messageWidth = this.sys.game.config.width * 0.2;
        let messageYPosition = this.sys.game.config.height * 0.3;
        this.createGradientBackground(messagesXposition, messageYPosition, messageWidth, pageBackgroundHeight, 0xFBBC05, 0.8);

        const authYPosition = this.sys.game.config.height * 0.8;

        // Define the text and style for the message
        let messageText = 'Well done!';

        // Use the createAnimatedText method to display the message
        this.createAnimatedText(messageXPosition, messageYPosition, messageText, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: this.getStrokeThickness(),
            align: 'left'
        }).setDepth(1000);

        messageYPosition = this.sys.game.config.height * 0.35;
        this.createGradientBackground(messagesXposition, messageYPosition, messageWidth, pageBackgroundHeight, 0xFBBC05, 0.8);
        messageText = `Your score: ${points}`;

        // Use the createAnimatedText method to display the message
        this.createAnimatedText(messageXPosition, messageYPosition, messageText, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: this.getStrokeThickness(),
            align: 'left'
        }).setDepth(1000);





        if (isAuthenticated) {
            const incorrectAnswers = this.registry.get('incorrectAnswers') || [];
            const userSub = this.game.registry.get('userSub');
            const gameName = this.game.registry.get('gameName');
            const incorrectAnswerMode = this.registry.get('incorrectAnswerMode') || false;
            const totalScore = score;  // This might be calculated differently depending on your game mechanics
            const level = 1;


            if (!incorrectAnswerMode) {
                window.dispatchEvent(new CustomEvent('updatePhaserPoints', {
                    detail: {
                        userSub: userSub,
                        correctAnswers: correctCount, //questions right
                        gameName: gameName,
                        level: 1, //this needs to be passed in - so that
                        totalScore: points,//questions plus the mulitpliers etc
                        incorrectAnswers: incorrectAnswers
                    }
                }));
                // Normal mode, dispatch updatePhaserPoints event

            }

            if (incorrectAnswerMode) {
                // console.log('We are in incorrect mode on game summary');

                const reduceAnswers = this.registry.get('correctAnswers') || [];

                // console.log('Dispatching reduceIncorrectCounters event', {
                //     userSub: userSub,
                //     correctAnswers: correctCount,
                //     reduceAnswers: reduceAnswers
                // });

                window.dispatchEvent(new CustomEvent('reduceIncorrectCounters', {
                    detail: {
                        userSub: userSub,
                        correctAnswers: correctCount,
                        reduceAnswers: reduceAnswers
                    }
                }));
            }


            setTimeout(() => {
                // console.log('Attempting to fetch user data after delay');
                this.fetchUserData();  // Ensure fetchUserData is appropriately defined to handle this operation
                // console.log('game registry points', this.game.registry.get('userData').points);
                // console.log('updated points', userData.points);
            }, 100);


        }

        if (!isAuthenticated) {
            const incorrectAnswers = this.registry.get('incorrectAnswers') || [];
            const gameName = this.game.registry.get('gameName');
            const correctAnswers = correctCount;  // Assuming you have a variable for correct answers
            const totalScore = score;  // This might be calculated differently depending on your game mechanics
            const dateTime = new Date().toISOString();
            const level = 1;  // Adjust as necessary

            // Update local storage with new game data
            let localStorageGames = JSON.parse(localStorage.getItem('games')) || [];

            localStorageGames.push({
                gameName: gameName,
                dateTime: dateTime,
                points: correctAnswers,
                totalScore: totalScore,
                incorrectAnswers: incorrectAnswers.length,
                level: level
            });

            localStorage.setItem('games', JSON.stringify(localStorageGames));

            // Update points in local storage
            let localStoragePoints = parseInt(localStorage.getItem('points'), 10) || 0;
            localStoragePoints += correctAnswers;
            localStorage.setItem('points', localStoragePoints);

            // Update total score in local storage
            let localStorageTotalScore = parseInt(localStorage.getItem('totalScore'), 10) || 0;
            localStorageTotalScore += totalScore;
            localStorage.setItem('totalScore', localStorageTotalScore);

            // Update last login time
            localStorage.setItem('lastLogin', dateTime);

            // Update game-specific data
            let gameData = JSON.parse(localStorage.getItem(gameName)) || {};

            gameData.lastLogin = dateTime;
            gameData.level = level;
            gameData.bestScore = Math.max(gameData.bestScore || 0, totalScore);

            // Update incorrect answers for the specific game
            gameData.incorrectAnswers = (gameData.incorrectAnswers || []).concat(incorrectAnswers);

            localStorage.setItem(gameName, JSON.stringify(gameData));

            // this.createAnimatedText(messageXPosition, authYPosition, "Remember to log in to see more game types", {
            //     fontFamily: 'gaegu',
            //     fontSize: `${baseFontSize}px`,
            //     color: '#f78737',
            //     stroke: '#000000',
            //     strokeThickness: strokeThickness,
            //     align: 'left'
            // });

            this.time.delayedCall(3000, () => {
                this.createButton(
                    this.sys.game.config.width * 0.75,
                    this.sys.game.config.height * 0.65,
                    'loginButton',
                    null,
                    () => window.dispatchEvent(new CustomEvent('triggerLogin'))
                );
            });

        };

        // this.clearGameData();

    }

    clearGameData() {
        // Clearing all game-related data from the registry
        this.registry.remove('score');
        this.registry.remove('points');
        this.registry.remove('incorrectAnswers');
        this.registry.remove('correctAnswers');
        this.registry.remove('currentQuestionIndex');  // Make sure to reset this as well!
        this.registry.remove('questions');
        this.registry.remove('incorrectAnswerMode');

    }

    displayCurrentIncorrectAnswer() {
        const incorrectAnswers = this.registry.get('incorrectAnswers') || [];


        // Ensure textGroup is a valid Phaser Group
        if (!this.textGroup) {
            this.textGroup = this.add.group();
        }

        // Clear the group if it exists and has a clear method
        if (this.textGroup && typeof this.textGroup.clear === 'function') {
            this.textGroup.clear(true, true);
        } else {
            console.warn('textGroup is not properly initialized or does not have a clear method.');
        }

        if (incorrectAnswers.length > 0) {
            const currentAnswer = incorrectAnswers[this.currentAnswerIndex];

            if (currentAnswer) {
                const answerText = `${this.currentAnswerIndex + 1} of ${incorrectAnswers.length}: ${currentAnswer.questionText}\nAnswered: ${currentAnswer.selectedAnswer}\nCorrect: ${currentAnswer.correctAnswer}`;
                const messagesXPosition = this.sys.game.config.width * 0.4;
                const messageXPosition = this.sys.game.config.width / 2;
                let messageYPosition = this.sys.game.config.height * 0.4;
                let message2YPosition = this.sys.game.config.height * 0.45;
                let message3YPosition = this.sys.game.config.height * 0.5;
                const pageBackgroundWidth = this.sys.game.config.width * 0.2;
                const pageBackgroundHeight = this.sys.game.config.height * 0.05;
                let answeredYPosition = this.sys.game.config.height * 0.55;
                const answeredBackgroundHeight = this.sys.game.config.height * 0.05;
                let correctYPosition = this.sys.game.config.height * 0.6;
                const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));

                this.createGradientBackground(messagesXPosition, messageYPosition, pageBackgroundWidth, pageBackgroundHeight, 0xEA4335, 0.8);
                this.createGradientBackground(messagesXPosition, message2YPosition, pageBackgroundWidth, pageBackgroundHeight, 0xEA4335, 0.8);
                this.createGradientBackground(messagesXPosition, message3YPosition, pageBackgroundWidth, pageBackgroundHeight, 0xEA4335, 0.8);
                this.createGradientBackground(messagesXPosition, answeredYPosition, pageBackgroundWidth, answeredBackgroundHeight, 0x4285F4, 0.8);
                this.createGradientBackground(messagesXPosition, correctYPosition, pageBackgroundWidth, answeredBackgroundHeight, 0x34A853, 0.8);
                let messageText = 'Incorrect Answer:';

                // Use the createAnimatedText method to display the message
                // this.createAnimatedText(messageXPosition, messageYPosition, messageText, {
                //     fontFamily: 'gaegu',
                //     fontSize: `${baseFontSize}px`,
                //     color: '#f78737',
                //     stroke: '#000000',
                //     strokeThickness: this.getStrokeThickness(),
                //     align: 'left'
                // }).setDepth(1000);

                messageYPosition = this.sys.game.config.height * 0.4;

                const text = this.add.text(messagesXPosition, messageYPosition, `Incorrect Answer:\n${answerText}`, {
                    fontFamily: 'gaegu',
                    fontSize: `${baseFontSize}px`,
                    color: '#ff4040',  // Change color to indicate incorrectness
                    stroke: '#000000',
                    strokeThickness: this.getStrokeThickness(),
                    align: 'center'
                }).setDepth(1000);

                this.textGroup.add(text);
            } else {
                console.warn('Current answer is undefined at index:', this.currentAnswerIndex);
            }
        } else {
            console.warn('No incorrect answers found.');
        }
    }



    createNavigationButtons() {
        const menuButtonXPosition = this.sys.game.config.width * 0.75;
        const menuButtonYupPosition = this.sys.game.config.height * 0.2;
        const menuButtonYdownPosition = this.sys.game.config.height * 0.8;

        const navButtonUp = this.createButton(menuButtonXPosition, menuButtonYupPosition, 'navButton', null, () => {
            this.currentAnswerIndex = (this.currentAnswerIndex + 1) % (this.registry.get('incorrectAnswers') || []).length;
            this.displayCurrentIncorrectAnswer();
        });
        navButtonUp.angle = -90;  // Rotate button upwards

        const navButtonDown = this.createButton(menuButtonXPosition, menuButtonYdownPosition, 'navButton', null, () => {
            const length = (this.registry.get('incorrectAnswers') || []).length;
            this.currentAnswerIndex = (this.currentAnswerIndex - 1 + length) % length;
            this.displayCurrentIncorrectAnswer();
        });
        navButtonDown.angle = 90;  // Rotate button downwards
    }


}

export default GameSummaryScene;
