import { getRandomNumber, shuffleArray } from "./utility";

export const generateSymbolQuestion = (level) => {
    let numRange, operators, maxMultiplier, maxDivisor;

    // console.log('Level Object:', level);


    // Define the range of numbers and available operators for each level
    if (level <= 3) {
        numRange = 5;
        operators = ['+', '-'];
    } else if (level <= 6) {
        numRange = 10;
        operators = ['+', '-'];
    } else if (level <= 9) {
        numRange = 10;
        operators = ['+', '-', 'x'];
        maxMultiplier = 5;
    } else if (level <= 12) {
        numRange = 15;
        operators = ['+', '-', 'x'];
        maxMultiplier = 10;
    } else if (level <= 15) {
        numRange = 20;
        operators = ['+', '-', 'x'];
        maxMultiplier = 10;
    } else if (level <= 18) {
        numRange = 20;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 10;
        maxDivisor = 5;
    } else if (level <= 21) {
        numRange = 30;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 15;
        maxDivisor = 10;
    } else if (level <= 24) {
        numRange = 50;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 15;
        maxDivisor = 15;
    } else if (level <= 27) {
        numRange = 75;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 20;
        maxDivisor = 20;
    } else {
        numRange = 100;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 20;
        maxDivisor = 20;
    }

    // Generate numbers and operator
    let num1 = getRandomNumber(1, numRange);
    let num2 = getRandomNumber(1, numRange);
    let correctAnswer;
    let correctOperator;

    const operatorIndex = getRandomNumber(1, operators.length);
    switch (operators[operatorIndex - 1]) {
      case '+':
        correctOperator = '+';
        correctAnswer = num1 + num2;
        break;
      case '-':
        correctOperator = '-';
        num1 = getRandomNumber(num2 + 1, numRange); // Ensure first number is bigger than the second
        correctAnswer = num1 - num2;
        break;
      case '÷':
        correctOperator = '÷';
        if (level > 18) { // Division is introduced at level 7
            num1 = getRandomNumber(Math.ceil(num2 / maxDivisor), numRange) * num2; // Ensure first number is a multiple of the second
            correctAnswer = num1 / num2;
        }
        break;
      case 'x':
        correctOperator = 'x';
        if (level > 9) { // Multiplication is introduced at level 4
            num1 = getRandomNumber(1, maxMultiplier);
            num2 = getRandomNumber(1, maxMultiplier);
            correctAnswer = num1 * num2;
        }
        break;
      default:
        break;
    }

    const question = `${num1} ? ${num2} = ${correctAnswer}`;

    return {
        question,
        answer: correctOperator,
        buttonOptions: shuffleArray(operators)
    };
};
